.bg-main {
  background-color: #ffe21f;
}

.btn-outline-main {
  background-color: #ffe21f !important;
  color: #111 !important;
  border: #111 solid 1px !important;
}

